import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, KeepAlive as _KeepAlive, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
import { useStore } from "vuex";
import { onBeforeMount, ref } from "vue";
import { useRouter } from "vue-router";
export default {
  __name: 'App',
  setup(__props) {
    let {
      state,
      dispatch
    } = useStore();
    const tabarShow = ref(true);
    onBeforeMount(() => {
      dispatch("getDeviceUsageInfo");
      dispatch("getDeviceNextLottery");
    });
    const router = useRouter();
    router.beforeEach((to, from) => {
      if (to.name == "indexs" || to.name == "discovers" || to.name == "platforms" || to.name == "mes") {
        tabarShow.value = true;
      } else {
        tabarShow.value = false;
      }
    });

    // watch: {
    //     $route(to, from) {
    //       // if the route changes...
    //       let token = localStorage.getItem("court-token") || "";
    //       if (token) {
    //         // firebase returns null if user logged out
    //         this.isLoggedIn = true;
    //       } else {
    //         this.isLoggedIn = false;
    //       }
    //     },
    //   },

    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      const _component_tabbar = _resolveComponent("tabbar");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_router_view, null, {
        default: _withCtx(({
          Component
        }) => [(_openBlock(), _createBlock(_KeepAlive, null, [_ctx.$route.meta.keepAlive ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
          key: _ctx.$route.name
        })) : _createCommentVNode("", true)], 1024)), !_ctx.$route.meta.keepAlive ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
          key: _ctx.$route.name
        })) : _createCommentVNode("", true)]),
        _: 1
      }), tabarShow.value ? (_openBlock(), _createBlock(_component_tabbar, {
        key: 0
      })) : _createCommentVNode("", true)], 64);
    };
  }
};