import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import regRoute from './register';
import pageRoute from './page';
import userRoute from './user';
import statisticsRoute from './statistics'
import guessRoute from './guess'
import galleryRoute from './gallery'
import masterRoute from './master'
import toolRoute from './tool'
import meRoute from './me/me';
import discoverRoute from './discover';
import platformRoute from './platform'
import wechatRoute from './wechat'
import index from '@/views/page/index.vue';
// import main from '@/views/main.vue';
import me from '@/views/me/index.vue';
import discover from '@/views/discover/index.vue';
import platform from '@/views/platform/index.vue';

const routes = [
  // {
  //   path: '/',
  //   name: 'home',
  //   component: main,
  //   redirect: '/index',

  //   children:[
  {
    path: '/default',
    name: 'default',
    component: () => import('@/views/page/default.vue'),
  },
  {
    path: '/index',
    name: 'indexs',
    component: index,
    meta: {
      keepAlive: true,
      top: true,
    },
  },
  {
    path: '/me',
    name: 'mes',
    // meta: {
    //   keepAlive: true,
    // },
    component: me,
  },
  {
    path: '/discover',
    name: 'discovers',
    meta: {
      keepAlive: true,
    },
    component: discover
  },
  {
    path: '/guess/index',
    name: 'guessIndex',
    meta: {
      needLogin: true
    },
    component: () => import('@/views/guess/index.vue'),
  },
  {
    path: '/guess/history',
    name: 'guessHistory',
    meta: {
      needLogin: true
    },
    component: () => import('@/views/guess/history.vue'),
  },
  {
    path: '/national',
    name: 'nationalDay',
    meta: {
      needLogin: true
    },
    component: () => import('@/views/page/nationalDay/index.vue'),
  },
  {
    path: '/national/ranks',
    name: 'ranks',
    meta: {
      needLogin: true
    },
    component: () => import('@/views/page/nationalDay/ranks.vue'),
  },
  {
    path: '/national/receives',
    name: 'receivess',
    meta: {
      needLogin: true
    },
    component: () => import('@/views/page/nationalDay/receives.vue'),
  },

  {
    path: '/platform',
    name: 'platforms',
    meta: {
      keepAlive: true,
    },
    component: platform
  },
  //   ]
  // }
]
routes.push(...regRoute, ...pageRoute, ...userRoute, ...statisticsRoute, ...guessRoute, ...galleryRoute, ...masterRoute, ...toolRoute, ...meRoute, ...discoverRoute, ...platformRoute, ...wechatRoute);

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  // history: createWebHashHistory(), // 使用 hash 模式
  // history: createWebHashHistory(process.env.BASE_URL),
  routes
})
router.beforeEach(function (to, from, next) {
  if (to.meta.needLogin) {
    //页面是否登录
    if (localStorage.getItem("Authorization")) {
      //本地存储中是否有token(uid)数据
      next(); //表示已经登录
    } else {
      //next可以传递一个路由对象作为参数 表示需要跳转到的页面
      next({
        name: "login"
      });
    }
  } else {
    next(); //继续往后走
  }
  // if(!to.meta.top){
  //   window.scrollTo(0,0)
  // }
});

router.beforeEach(function (to, from, next) {
  if (to.matched.length === 0) {
    next({
      name: "indexs"
    });

  } else {
    next(); //继续往后走
  }
});

export default router
